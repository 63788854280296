import { graphql, Link } from 'gatsby';
import moment from 'moment';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { CTAButton } from '../components/shared/layout/cta';
import FxLayout from '../components/shared/layout/layout';
import uiService from '../components/shared/ui-service';

export const query = graphql`
  query {
    site {
      siteMetadata {
        name
        tagline
        siteUrl
      }
    }
  }
`;

export default class IndexPage extends React.Component<any, any> {
  uiService = uiService;
  constructor(props: any) {
    super(props);

    this.state = {
      packageRange: [],
      selectedPackage: -1,
      collectionLayout: true,
    };
    this.uiService.headerInverted = true;
    this.setCollectionLayout = this.setCollectionLayout.bind(this);
  }

  setCollectionLayout() {
    this.setState({ collectionLayout: !this.state.collectionLayout });
  }

  render() {
    const pageContext = this.props.pageContext;
    const seoMeta = pageContext.seoMeta && pageContext.seoMeta.primary;
    const contentPanels = pageContext.contentPanels || [];
    const isSDTR = process.env.TENANT === 'sdtr';
    const isVisory = process.env.TENANT === 'sigma';

    let pageBG;

    if (pageContext.data && pageContext.data.background_image) {
      pageBG = {
        backgroundImage: `url(${pageContext.data.background_image.url || ''})`,
      };
    }

    return (
      <FxLayout sitemap={pageContext.sitemap}>
        {
          <Helmet>
            <title>{seoMeta && seoMeta.seo_title.text}</title>
            <meta name="keywords" content={seoMeta && seoMeta.seo_keywords.text} />
            <meta name="description" content={seoMeta && seoMeta.seo_description.text} />
            <link
              type="canonical"
              href={`${this.props.data.site.siteMetadata.siteUrl}${pageContext.page && pageContext.page.url}`}
            />
          </Helmet>
        }
        <div
          className="fx-l-home"
          style={{
            backgroundImage: pageBG && pageBG.backgroundImage ? pageBG.backgroundImage : '',
          }}
        >
          {contentPanels.map((panel: any) => {
            if (panel.type !== 'collection') {
              return panel.items.map((item: any) => {
                if (item) {
                  const templates = item.content.document && item.content.document.data.templates;
                  const data = item.content.document && item.content.document.data;
                  if (templates) {
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('grid');
                      })
                    ) {
                      return (
                        <section
                          className="fx-l-section fx-l-grid"
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-container">
                            <h1 className="fx-l-hero-title">
                              {isSDTR && 'Tax Tips'}
                              {isVisory && 'Latest News'}
                            </h1>
                            <div className="fx-layout">
                              <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12 fx-layout-col-laptop--6">
                                {data.title.text}
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    }
                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid === 'fx-l-hero-unit';
                      })
                    ) {
                      return (
                        <div className={`fx-l-hero`} key={`${new Date().getMilliseconds()}-${Math.random()}`}>
                          <div className="fx-l-hero-content">
                            <div className="fx-container">
                              <h1 className="fx-l-hero-title">{data.title.text}</h1>
                              <h2 className="fx-l-hero-subtitle">{data.body.text}</h2>
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </div>
                      );
                    }

                    if (
                      templates.find((template: any) => {
                        return template.template && template.template.uid.includes('fx-l-section--split');
                      })
                    ) {
                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              <div className="fx-layout">
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  {data.images.find((image: any) => image.image.url) &&
                                    data.images.map((image: any) => (
                                      <img
                                        className="fx-l-section-media"
                                        key={`image--${new Date().getMilliseconds()}-${Math.random()}`}
                                        src={image.image.url}
                                        alt={image.image.alt}
                                      />
                                    ))}
                                </div>
                                <div className="fx-layout-col-mobile--24 fx-layout-col-tablet--12">
                                  <h3 className="fx-l-section-title">{data.title.text}</h3>
                                  <p className="fx-l-section-body">{data.body.text}</p>
                                  <CTAButton data={data} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      );
                    } else {
                      let sectionBgImage;

                      if (data.background_image && data.background_image.url) {
                        sectionBgImage = {
                          backgroundImage: `url(${data.background_image && data.background_image.url})`,
                          backgroundSize: 'cover',
                        };
                      }

                      return (
                        <section
                          className={`fx-l-section ${templates
                            .map((template: any) => `${template.template && template.template.uid}`)
                            .join()
                            .replace(',', ' ')}`}
                          key={`${new Date().getMilliseconds()}-${Math.random()}`}
                          style={sectionBgImage}
                        >
                          <div className="fx-l-section-content">
                            <div className="fx-container">
                              <h3 className="fx-l-section-title">{data.title.text}</h3>
                              <p className="fx-l-section-body">{data.body.text}</p>
                              <CTAButton data={data} />
                            </div>
                          </div>
                        </section>
                      );
                    }
                  } else {
                    return null;
                  }
                }
                return null;
              });
            } else {
              return (
                <div className="fx-l-collection fx-l-articles" key={`${new Date().getMilliseconds()}-${Math.random()}`}>
                  <div className="fx-container">
                    <h1 className="fx-l-hero-title">
                      {isSDTR && 'Tax Tips'}
                      {isVisory && 'Latest News'}
                    </h1>
                    <div className="fx-layout">
                      {panel &&
                        panel.items.map((item: any) => {
                          return (
                            <div
                              className={
                                this.state.collectionLayout
                                  ? 'fx-layout-col-mobile--24 fx-layout-col-tablet--12 fx-layout-col-laptop--8 fx-layout-col-desktop--6'
                                  : 'fx-layout-col-mobile--24 '
                              }
                              key={`${new Date().getMilliseconds()}-${Math.random()}`}
                            >
                              <section
                                className={`fx-media-tile`}
                                key={`${new Date().getMilliseconds()}-${Math.random()}`}
                              >
                                <Link
                                  to={item.url}
                                  className="fx-media-tile-thumb"
                                  style={{
                                    backgroundImage: `url(${item.data.background_image.url}`,
                                  }}
                                />
                                <div className="fx-media-tile-content">
                                  <div className="fx-media-tile-tags">
                                    {item.tags.slice(0, 2).map((tag: string) => (
                                      <span
                                        className="fx-media-tile-tags-item"
                                        key={`${new Date().getMilliseconds()}-${Math.random()}`}
                                      >
                                        {tag}
                                      </span>
                                    ))}
                                  </div>
                                  <h3 className="fx-media-tile-title">
                                    <Link to={item.url}>{item.data.title.text}</Link>
                                  </h3>
                                  <p className="fx-media-tile-date">
                                    {item.data.date_published
                                      ? moment(item.data.date_published).format('DD MMMM YYYY')
                                      : moment(pageContext.page && pageContext.page.node.first_publication_date).format(
                                          'DD MMMM YYYY'
                                        )}
                                    {item.data.authors[0].author.text && (
                                      <span> | {item.data.authors[0].author.text}</span>
                                    )}
                                  </p>
                                  <p className="fx-media-tile-body">
                                    {item.data.body.text.substring(0, 84)}... <Link to={item.url}>read more</Link>
                                  </p>
                                </div>
                              </section>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </FxLayout>
    );
  }
}
