import { Link } from 'gatsby';
import React from 'react';
import { fbq } from '@hutsoninc/gatsby-plugin-facebook-pixel';

interface ICTAProps {
  data: {
    cta_title?: {
      text?: string;
    };
    cta_link?: {
      text?: string;
    };
    cta_is_external?: boolean;
    cta_analytics_event?: {
      text?: string;
    };
  };
}

const ctaEvent = (analyticsEvent: ICTAProps['data']['cta_analytics_event']) => {
  if (analyticsEvent?.text) {
    fbq('track', analyticsEvent?.text);
  }
};

const APP_URL = process.env.APP_URL;

const ctaButton: React.FC<ICTAProps> = ({ data }) => {
  if (!data) {
    return null;
  }
  const { cta_title, cta_link, cta_is_external, cta_analytics_event } = data;

  const parsedCtaLink = cta_link?.text && cta_link.text.replace('#{APP_URL}', APP_URL || '');

  if (cta_title?.text && parsedCtaLink) {
    return (
      <div onClick={() => ctaEvent(cta_analytics_event)}>
        {cta_title.text && cta_is_external && (
          <a className="fx-l-section-cta fx-btn fx-btn--primary-outlined" href={parsedCtaLink}>
            {cta_title.text}
          </a>
        )}
        {cta_title.text && !cta_is_external && (
          <Link className="fx-l-section-cta fx-btn fx-btn--primary-outlined" to={parsedCtaLink}>
            {cta_title.text}
          </Link>
        )}
      </div>
    );
  } else {
    return null;
  }
};

export { ctaButton as CTAButton };
